














import { Component, Mixins, Prop } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import { LlmParameter, DefaultLlmParameter } from "#/model/admin/index";
import RulesMixin from "#/mixins/rulesMixin";
import LLMParameterSetting from "@/components/ai/LLMParameterSetting.vue";

@Component({ components: { LLMParameterSetting } })
/**
 * OpenAI設定
 */
export default class extends Mixins(UtilMixin, RulesMixin) {
  /** Prop */

  /** 入力値 */
  @Prop({ default: () => DefaultLlmParameter() }) value!: LlmParameter;

  /** 詳細パラメータ表示フラグ */
  @Prop({ default: () => false }) showParameterSetting!: boolean;

  /** 変数 */

  /** geminiモデル選択肢 */
  private geminiChoice = [
    { text: "Gemini 1.5 Pro", value: "gemini-1.5-pro" },
    { text: "Gemini 1.5 Flash", value: "gemini-1.5-flash" },
  ];
}
