


















































































































// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference types="@types/googlemaps" />
/*global google*/
import { Component, Emit, Prop, Mixins } from "vue-property-decorator";
import { PREFECTURES } from "../const/prefectures";
import AppButton from "./AppButton.vue";
import AppSelect from "./AppSelect.vue";
import AppTextField from "./AppTextField.vue";
import NumberInputString from "./NumberInputString.vue";
import AppMapButton from "./AppMapButton.vue";
import RulesMixin from "../mixins/rulesMixin";

interface Latlng {
  lat: number;
  lng: number;
  isUpdate: boolean;
}

@Component({
  components: {
    AppButton,
    AppSelect,
    AppTextField,
    NumberInputString,
    AppMapButton
  }
})
export default class AppPlaces extends Mixins(RulesMixin) {
  /** 郵便番号 */
  @Prop() private readonly postal_code!: string;

  /** 都道府県 */
  @Prop() private readonly prefecture!: number;

  /** 市区町村 */
  @Prop() private readonly cityAddress!: string;

  /** 番地 */
  @Prop() private readonly houseNumber!: string;

  /** 建物 */
  @Prop() private readonly building!: string;

  /** 緯度経度情報 */
  @Prop() private readonly latlng!: Latlng;

  /** 必須 郵便番号 */
  @Prop({ default: false }) private readonly requiredPostCode!: boolean;

  /** 必須 都道府県 */
  @Prop({ default: false }) private readonly requiredPrefecture!: boolean;

  /** 必須 市区町村 */
  @Prop({ default: false }) private readonly requiredCityAddress!: boolean;

  /** 必須 番地 */
  @Prop({ default: false }) private readonly requiredHouseNumber!: boolean;

  /** 必須 建物 */
  @Prop({ default: false }) private readonly requiredBuilding!: boolean;

  /** 読み取り専用 */
  @Prop({ default: false }) private readonly isReadOnly!: boolean;

  /** 無効フラグ */
  @Prop({ default: false }) private readonly disabled!: boolean;

  /** idに設定するprefix */
  @Prop({ default: "prefix" }) private readonly prefix_id!: string;

  /** 地図表示フラグ */
  @Prop({ default: false }) private readonly isUseMap!: boolean;

  /** 都道府県リスト */
  private prefectures = [{ text: "未選択", value: 0 }].concat(PREFECTURES);

  /** 郵便番号検索フラグ */
  private address_loading = false;

  /** 都道府県検索フラグ */
  private postcode_loading = false;

  /** 位置情報変更フラグ -1:初期値 0:変更なし 1:変更あり */
  private isLatLngSave = -1;

  @Emit("update:postal_code") updatePostalCode(newValue: string) {
    return newValue;
  }

  @Emit("update:prefecture") updatePrefecture(newValue: number) {
    return newValue;
  }

  @Emit("update:cityAddress") updateCityAddress(newValue: string) {
    return newValue;
  }

  @Emit("update:houseNumber") updateHouseNumber(newValue: string) {
    return newValue;
  }

  @Emit("update:building") updateBuilding(newValue: string) {
    return newValue;
  }

  @Emit("update:latlng") updateLatLng(newValue: Latlng) {
    return newValue;
  }

  /** 郵便番号 */
  get PostalCode() {
    return this.postal_code;
  }
  set PostalCode(newValue: string) {
    this.updatePostalCode(newValue);
  }

  /** 都道府県 */
  get Prefecture() {
    return this.prefecture;
  }
  set Prefecture(newValue: number) {
    this.resetedLatlng();
    this.updatePrefecture(newValue);
  }

  /** 市区町村 */
  get CityAddress() {
    return this.cityAddress;
  }
  set CityAddress(newValue: string) {
    this.resetedLatlng();
    this.updateCityAddress(newValue);
  }

  /** 番地 */
  get HouseNumber() {
    return this.houseNumber;
  }
  set HouseNumber(newValue: string) {
    this.resetedLatlng();
    this.updateHouseNumber(newValue);
  }

  /** 建物 */
  get Building() {
    return this.building;
  }
  set Building(newValue: string) {
    this.updateBuilding(newValue);
  }

  /** 緯度経度 */
  private get Latlng() {
    if (this.isLatLngSave === 0) {
      return { lat: 0, lng: 0 };
    }
    return this.latlng;
  }

  /** 選択された都道府県の名称 */
  get PrefectureText() {
    const selectedPref = this.prefectures.find(
      pref => pref.value === this.Prefecture
    );
    return selectedPref ? selectedPref.text : "";
  }

  /** 郵便番号から都道府県と市町村取得 */
  private async fromPostalCode() {
    if (this.postal_code.toString().length !== 7) {
      await this.$openAlert("郵便番号を7桁入力してください。");
      return;
    }
    this.address_loading = true;
    this.getLocation(this.postal_code.toString(), address => {
      const administrativeArea2 =
        this.getMapData(address, "administrative_area_level_2") || "";
      const locality = this.getMapData(address, "locality") || "";
      const sublocality1 =
        this.getMapData(address, "sublocality_level_1") || "";
      const sublocality2 =
        this.getMapData(address, "sublocality_level_2") || "";

      this.CityAddress =
        administrativeArea2 + locality + sublocality1 + sublocality2;

      const prefecture = this.getMapData(
        address,
        "administrative_area_level_1"
      );
      if (prefecture) {
        const prefecture_no = this.prefectures.find(p => {
          return p.text === prefecture;
        });
        if (prefecture_no) {
          this.Prefecture = prefecture_no.value;
        }
      }
    });
  }

  /** 都道府県と市町村から郵便番号取得 */
  private fromPrefCity() {
    this.postcode_loading = true;
    this.getLocation(this.PrefectureText + this.CityAddress, async address => {
      const postal_code = this.getMapData(address, "postal_code");
      if (postal_code) {
        this.PostalCode = postal_code.replace("-", "");
      } else {
        await this.$openAlert("郵便番号が見つかりませんでした。");
      }
    });
  }

  private getMapData(
    address: google.maps.GeocoderAddressComponent[],
    key: string
  ): string | undefined {
    const findAddress = address.find(ad => {
      for (const type of ad.types) {
        if (type === key) {
          return true;
        }
      }
      return false;
    });
    if (findAddress) {
      return findAddress.long_name;
    }
    return undefined;
  }

  private getLocation(
    param: string,
    callback: (address: google.maps.GeocoderAddressComponent[]) => void
  ) {
    const geocoder: google.maps.Geocoder = new google.maps.Geocoder();
    geocoder.geocode(
      {
        address: param,
        region: "jp"
      },
      (
        results: google.maps.GeocoderResult[],
        status: google.maps.GeocoderStatus
      ) => {
        this.address_loading = false;
        this.postcode_loading = false;
        if (status === google.maps.GeocoderStatus.OK) {
          callback(results[0].address_components);
        }
      }
    );
  }

  savedLatLng(latlng: { lat: number; lng: number }) {
    this.isLatLngSave = 1;
    this.updateLatLng({ lat: latlng.lat, lng: latlng.lng, isUpdate: true });
  }

  resetedLatlng() {
    if (this.isLatLngSave === 1) {
      this.$openConfirm(
        "住所が変更されたため、反映された位置情報がリセットされました。"
      );
    }
    this.isLatLngSave = 0;
    this.updateLatLng({ lat: 0, lng: 0, isUpdate: false });
  }
}
