







































































































































































































































































































import { Component, Mixins } from "vue-property-decorator";
import ChangeContractDialog from "@/components/contractmanagement/ChangeContractDialog.vue";
import OfficeStaffChangeDialog from "@/components/contractmanagement/OfficeStaffChangeDialog.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import OfficeInfo from "@/components/contractreception/OfficeInfo.vue";
import AppAuthButton from "@/components/admin_common/AppAuthButton.vue";
import OutputExcelDialog from "@/components/contractmanagement/OutputExcelDialog.vue";
import InputExcelDialog from "@/components/contractmanagement/InputExcelDialog.vue";

import { PREFECTURES } from "@/consts/const";

import {
  PORTAL_OFFICE_IS_PUBLIC,
  PORTAL_OFFICE_APPROVAL_STATUS,
  PORTAL_OFFICE_LISTING_REVIEW_STATUS,
} from "@/consts/portal";

import {
  PortalOrganization,
  PortalOrganizationInternalRemark,
  DEFAULT_PORTAL_ORGANIZATION,
  DEFAULT_PORTAL_ORGANIZATION_INTERNAL_REMARK,
} from "@/model/portal";

@Component({
  components: {
    ChangeContractDialog,
    OfficeInfo,
    AppAuthButton,
    OfficeStaffChangeDialog,
    OutputExcelDialog,
    InputExcelDialog,
  },
})
export default class PortalOrganizationEdit extends Mixins(
  AxiosMixin,
  UtilMixin,
) {
  /** 初回ロード完了フラグ */
  private initLoadingDone = false;
  private organization: PortalOrganization = {
    ...DEFAULT_PORTAL_ORGANIZATION,
  };
  private internalRemark: PortalOrganizationInternalRemark = {
    ...DEFAULT_PORTAL_ORGANIZATION_INTERNAL_REMARK,
  };
  private showSaveCompleteBar = false;

  private PREFECTURES = PREFECTURES;
  private PORTAL_OFFICE_IS_PUBLIC = PORTAL_OFFICE_IS_PUBLIC;
  private PORTAL_OFFICE_APPROVAL_STATUS = PORTAL_OFFICE_APPROVAL_STATUS;
  private PORTAL_OFFICE_LISTING_REVIEW_STATUS =
    PORTAL_OFFICE_LISTING_REVIEW_STATUS;

  public async created() {
    await this.fetchItem(Number(this.$route.params.id));
    this.initLoadingDone = true;
  }

  /** 契約情報取得 */
  private async fetchItem(id: number) {
    return new Promise((resolve) => {
      this.postJsonCheck(
        window.base_url + "/api/admin/portal_organization/get",
        { organization_id: id },
        (res) => {
          this.organization = res.data.organization;
          if (res.data.internal_remark != undefined) {
            this.internalRemark = res.data.internal_remark;
          }
          resolve(0);
        },
      );
    });
  }

  /** 保存ボタンクリック時 */
  private async save(
    organization: PortalOrganization,
    internalRemark: PortalOrganizationInternalRemark,
  ) {
    if (!(await this.$openConfirm("保存しますか？"))) {
      return;
    }
    internalRemark.organization_id = organization.id;
    this.postJsonCheck(
      window.base_url + "/api/admin/portal_organization/save",
      {
        organization: organization,
        internal_remark: internalRemark,
      },
      (res) => {
        this.organization = res.data.organization;
        this.showSaveCompleteBar = true;

        setTimeout(() => {
          this.$router.push({
            path: `/portal/organization/detail/${organization.id}`,
          });
        }, 2000);
      },
    );
  }
  // キャンセルボタンクリック
  private async cancel(id: number) {
    if (
      await this.$openConfirm(
        "編集を終了します\n編集中の場合は内容が破棄されます",
      )
    ) {
      this.$router.push({ path: `/portal/organization/detail/${id}` });
    }
  }

  private async onSwitchOption1Change() {
    // 現在の値を記憶
    const currentValue = this.organization.patient_ext_option;

    // ユーザーに切り替えの確認を求める
    const result = await this.$openConfirm(
      "オプション機能の設定を切り替えます。よろしいですか？",
    );

    if (!result) {
      // ユーザーがキャンセルした場合、以前の状態に戻す（現在の値を反転）
      this.organization.patient_ext_option = currentValue === 1 ? 0 : 1;
    } else {
      console.log(
        "確認され、新しい値を保持:",
        this.organization.patient_ext_option,
      );
    }
  }

  private async onSwitchOption2Change() {
    // 現在の値を記憶
    const currentValue = this.organization.card_ext_option;
    console.log(
      "紹介カード管理拡張オプションの切り替え - 現在の値:",
      currentValue,
      typeof currentValue,
    );

    // ユーザーに切り替えの確認を求める
    const result = await this.$openConfirm(
      "オプション機能の設定を切り替えます。よろしいですか？",
    );

    if (!result) {
      // ユーザーがキャンセルした場合、以前の状態に戻す（現在の値を反転）
      this.organization.card_ext_option = currentValue === 1 ? 0 : 1;
      console.log(
        "キャンセルされ、元に戻しました:",
        this.organization.card_ext_option,
      );
    } else {
      console.log(
        "確認され、新しい値を保持:",
        this.organization.card_ext_option,
      );
    }
  }
}
