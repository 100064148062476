var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-simple-table',{staticClass:"elevation-1",attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('TR',{attrs:{"name":"AIRoute","value":_vm.idToText(_vm.billing.is_ai_route_enabled, _vm.USE_DIV)}}),_c('TR',{attrs:{"name":"開始日","value":_vm.dateToStr(_vm.newDate(_vm.billing.ai_route_start_date), _vm.DATE_FORMAT)}}),_c('TR',{attrs:{"name":"終了日","value":_vm.dateToStr(_vm.newDate(_vm.billing.ai_route_end_date), _vm.DATE_FORMAT)}}),_c('TR',{attrs:{"name":"契約開始日","value":_vm.dateToStr(
              _vm.newDate(_vm.billing.ai_route_agreement_start_date),
              _vm.DATE_FORMAT
            )}}),_c('TR',{attrs:{"name":"契約終了日","value":_vm.dateToStr(
              _vm.newDate(_vm.billing.ai_route_agreement_end_date),
              _vm.DATE_FORMAT
            )}}),_c('TR',{attrs:{"name":"課金開始日","value":_vm.dateToStr(
              _vm.newDate(_vm.billing.ai_route_billing_start_date),
              _vm.DATE_FORMAT
            )}}),_c('TR',{attrs:{"name":"課金終了日","value":_vm.dateToStr(_vm.newDate(_vm.billing.ai_route_billing_end_date), _vm.DATE_FORMAT)}}),_c('TR',{attrs:{"name":"請求管理システムへの送信","value":_vm.idToText(
              _vm.billing.ai_route_is_send_receipt_system,
              _vm.SEND_RECEIPT_SYSTEM_DIV
            )}}),_c('TR',{attrs:{"name":"通知メールアドレス","value":_vm.billing.ai_route_notice_mailaddress}})],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }