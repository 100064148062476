






































































































import Vue from "vue";
import Component from "vue-class-component";
import { Emit } from "vue-property-decorator";
import AppTextField from "#/components/AppTextField.vue";
import AppSelect from "#/components/AppSelect.vue";

export interface PortalSearchParams {
  code?: string;
  name?: string;
  cst_no?: string;
  kst_no?: string;
  approval_statuses?: number[];
  listing_review_statuses?: number[];
  type?: number[];
  is_carelogood?: boolean | null;
}

@Component({
  components: {
    AppTextField,
    AppSelect,
  },
})
export default class PortalSearchBox extends Vue {
  private code = "";
  private name = "";
  private cst_no = "";
  private kst_no = "";
  private selectedTypes = ["nursing", "medical"];
  private selectedCarelogood = [true];

  private typeOptions = [
    { value: "nursing", text: "訪問看護" },
    { value: "medical", text: "医療機関" },
  ];

  private carelogoodOptions = [
    { value: true, text: "あり" },
    { value: false, text: "なし" },
  ];

  @Emit()
  private search(): PortalSearchParams {
    const typeValues: number[] = [];
    if (this.selectedTypes.includes("nursing")) {
      typeValues.push(1);
      typeValues.push(2);
    }
    if (this.selectedTypes.includes("medical")) {
      typeValues.push(3);
    }

    // 掲載側登録の複数選択処理
    let isCarelogood = null;
    if (
      this.selectedCarelogood.includes(true) &&
      !this.selectedCarelogood.includes(false)
    ) {
      // 「あり」のみ選択
      isCarelogood = true;
    } else if (
      this.selectedCarelogood.includes(false) &&
      !this.selectedCarelogood.includes(true)
    ) {
      // 「なし」のみ選択
      isCarelogood = false;
    }
    // 両方選択または両方選択なしの場合、isCarelogoodはnullのままで、フィルタリングしない

    const params: PortalSearchParams = {
      code: this.code,
      name: this.name,
      cst_no: this.cst_no,
      kst_no: this.kst_no,
      type: typeValues.length > 0 ? typeValues : undefined,
      is_carelogood: isCarelogood,
    };

    return params;
  }
}
