





























































































































































































































































































































































































import { Component, Mixins } from "vue-property-decorator";
import ChangeContractDialog from "@/components/contractmanagement/ChangeContractDialog.vue";
import OfficeStaffChangeDialog from "@/components/contractmanagement/OfficeStaffChangeDialog.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import OfficeInfo from "@/components/contractreception/OfficeInfo.vue";
import AppAuthButton from "@/components/admin_common/AppAuthButton.vue";
import OutputExcelDialog from "@/components/contractmanagement/OutputExcelDialog.vue";
import InputExcelDialog from "@/components/contractmanagement/InputExcelDialog.vue";
import AppLoadingDialog from "#/components/AppLoadingDialog.vue";
import { Agreement } from "@/model/agreement";
import { DEFAULT_AGREEMENT } from "@/model/agreement/agreement";

import {
  PORTAL_OFFICE_IS_PUBLIC,
  PORTAL_OFFICE_IS_PUBLIC_COLOR,
  PORTAL_OFFICE_APPROVAL_STATUS,
  PORTAL_OFFICE_APPROVAL_STATUS_COLOR,
  PORTAL_OFFICE_LISTING_REVIEW_STATUS,
  PORTAL_OFFICE_LISTING_REVIEW_STATUS_COLOR,
} from "@/consts/portal";
import { Office, DEFAULT_OFFICE } from "@/model/office";
import { PortalStaff, DEFAULT_PORTAL_STAFF } from "@/model/portal";
import { DEFAULT_EWELLUSER } from "@/model/ewellUser";

@Component({
  components: {
    ChangeContractDialog,
    OfficeInfo,
    AppAuthButton,
    OfficeStaffChangeDialog,
    OutputExcelDialog,
    InputExcelDialog,
    AppLoadingDialog,
  },
})
export default class PortalOfficeDetail extends Mixins(AxiosMixin, UtilMixin) {
  /** 初回ロード完了フラグ */
  private initLoadingDone = false;
  private portalOffice = null;
  private internalRemark = null;
  private adminPortalStaff: PortalStaff = { ...DEFAULT_PORTAL_STAFF };
  private agreement: Agreement = { ...DEFAULT_AGREEMENT };
  private ibowOffice: Office | undefined = { ...DEFAULT_OFFICE };
  private showSaveCompleteBar = false;
  public loading = 0;
  private addressBookId = 0; // 住所録ID

  private PORTAL_OFFICE_IS_PUBLIC = PORTAL_OFFICE_IS_PUBLIC;
  private PORTAL_OFFICE_IS_PUBLIC_COLOR = PORTAL_OFFICE_IS_PUBLIC_COLOR;
  private PORTAL_OFFICE_APPROVAL_STATUS = PORTAL_OFFICE_APPROVAL_STATUS;
  private PORTAL_OFFICE_APPROVAL_STATUS_COLOR =
    PORTAL_OFFICE_APPROVAL_STATUS_COLOR;
  private PORTAL_OFFICE_LISTING_REVIEW_STATUS =
    PORTAL_OFFICE_LISTING_REVIEW_STATUS;
  private PORTAL_OFFICE_LISTING_REVIEW_STATUS_COLOR =
    PORTAL_OFFICE_LISTING_REVIEW_STATUS_COLOR;

  public async created() {
    try {
      await this.fetchPortalOffice(Number(this.$route.params.id));
    } catch (error) {
      console.error("ページの初期化中にエラーが発生しました:", error);
    } finally {
      this.initLoadingDone = true;
      this.loading = 0;
    }
  }

  /** ポータル事業所取得 */
  private async fetchPortalOffice(id: number) {
    this.loading = 1;
    return new Promise((resolve, reject) => {
      this.postJsonCheck(
        window.base_url + "/api/admin/portal_office/get",
        { portal_office_id: id },
        (res) => {
          console.log(res);
          this.portalOffice = res.data.portal_office;
          this.internalRemark = res.data.internal_remark;
          this.addressBookId = res.data.address_book_id || 0;

          // admin_portal_staffsが存在し、空でないかを確認
          if (
            res.data.admin_portal_staffs &&
            res.data.admin_portal_staffs.length > 0
          ) {
            this.adminPortalStaff = res.data.admin_portal_staffs[0];

            // ewell_userが存在することを確認
            if (!this.adminPortalStaff.ewell_user) {
              this.adminPortalStaff.ewell_user = DEFAULT_EWELLUSER;
            }
          } else {
            // admin_portal_staffsが空の場合、デフォルト値を設定
            this.adminPortalStaff = {
              ...DEFAULT_PORTAL_STAFF,
              ewell_user: DEFAULT_EWELLUSER,
            };
          }

          const ibow_agreement_id =
            res.data.portal_office.portal_agreement.ibow_agreement_id;
          const ibow_office_id = res.data.portal_office.ibow_office_id;
          this.fetchAgreement(ibow_agreement_id, ibow_office_id)
            .then(() => {
              resolve(0);
            })
            .catch((err) => {
              console.error("契約情報の取得中にエラーが発生しました:", err);
              this.loading = 0;
              this.initLoadingDone = true; // エラーが発生しても画面を表示する
              reject(err);
            });
        },
      );
    });
  }

  /** 契約情報取得 */
  private async fetchAgreement(id: number, ibow_office_id: number) {
    return new Promise((resolve, reject) => {
      this.postJsonCheck(
        window.base_url + "/api/admin/agreements/get",
        { is_display_confirm: 1, ids: [id] },
        (res) => {
          try {
            if (res.data.agreements && res.data.agreements.length > 0) {
              this.agreement = res.data.agreements[0];
              console.log(res);
              this.ibowOffice = this.agreement.offices.find(
                (office) => office.id == ibow_office_id,
              );
            } else {
              console.warn("契約情報が見つかりませんでした");
            }
            // console.log(this.ibowOffice);
            this.initLoadingDone = true;
            console.log(this.initLoadingDone);
            this.loading = 0;
            resolve(0);
          } catch (err) {
            console.error("契約情報の処理中にエラーが発生しました:", err);
            this.initLoadingDone = true;
            this.loading = 0;
            resolve(1); // エラーでもresolveして処理を続行
          }
        },
      );
    });
  }

  /** 詳細ボタンクリック時 */
  private goEdit(id: number) {
    /** 詳細画面へ遷移 */
    this.$router.push({ path: `/portal/portal-office-ibow/edit/${id}` });
  }

  /** Ibow企業情報詳細ボタンクリック時 */
  private goIbowAgreement(id: number) {
    /** 詳細画面へ遷移 */
    this.$router.push({ path: `/contractmanagement/detail/${id}` });
  }

  //代理ログイン
  private agentLogin(id: number) {
    this.postJsonCheck(
      window.base_url + "/api/admin/portallogin/portalstaff-agent-login",
      {
        portal_office_id: id,
      },
      (res) => {
        window.open(
          window.portal_url +
            "/signin?use_type=1&token=" +
            res.data.onetime_token,
          "_blank",
        );
      },
    );
  }

  //管理者ログイン
  private adminLogin(id: number) {
    this.postJsonCheck(
      window.base_url + "/api/admin/portallogin/portalstaff-admin-login",
      {
        portal_office_id: id,
      },
      (res) => {
        window.open(
          window.portal_url +
            "/signin?use_type=1&is_admin=true&token=" +
            res.data.onetime_token,
          "_blank",
        );
      },
    );
  }

  /** 承認するボタンクリック時 */
  private async approve(id: number) {
    if (!(await this.$openConfirm("承認しますか？"))) {
      return;
    }
    this.postJsonCheck(
      window.base_url + "/api/admin/portal_office/update_approval_status",
      { portal_office_id: id },
      () => {
        this.fetchPortalOffice(id).then(() => {
          this.showSaveCompleteBar = true;
        });
      },
    );
  }

  /** 掲載承認する */
  private async approveListingReviewStatus(id: number) {
    if (!(await this.$openConfirm("初回掲載情報を承認しますか？"))) {
      return;
    }
    this.postJsonCheck(
      window.base_url + "/api/admin/portal_office/update_listing_review_status",
      { portal_office_id: id },
      () => {
        this.fetchPortalOffice(id).then(() => {
          this.showSaveCompleteBar = true;
        });
      },
    );
  }

  /** iBow連携を停止する */
  private async stopIbowConnection(id: number) {
    if (!(await this.$openConfirm("iBow連携を停止しますか？"))) {
      return;
    }
    this.postJsonCheck(
      window.base_url + "/api/admin/portal_ibow_link/release",
      { portal_office_id: id },
      () => {
        this.fetchPortalOffice(id).then(() => {
          this.showSaveCompleteBar = true;
        });
      },
    );
  }

  /** 自動登録データ表示ボタンクリック時 */
  private openAddressBook(id: number) {
    window.open(`/portal/portal-office-no-ibow/detail/${id}`, "_blank");
  }
}
