






































































































































































































































































































































































































import { Component, Mixins } from "vue-property-decorator";
import ChangeContractDialog from "@/components/contractmanagement/ChangeContractDialog.vue";
import OfficeStaffChangeDialog from "@/components/contractmanagement/OfficeStaffChangeDialog.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import OfficeInfo from "@/components/contractreception/OfficeInfo.vue";
import AppAuthButton from "@/components/admin_common/AppAuthButton.vue";
import OutputExcelDialog from "@/components/contractmanagement/OutputExcelDialog.vue";
import InputExcelDialog from "@/components/contractmanagement/InputExcelDialog.vue";
import AppLoadingDialog from "#/components/AppLoadingDialog.vue";

import {
  PORTAL_OFFICE_IS_PUBLIC,
  PORTAL_OFFICE_IS_PUBLIC_COLOR,
  PORTAL_OFFICE_APPROVAL_STATUS,
  PORTAL_OFFICE_APPROVAL_STATUS_COLOR,
  PORTAL_OFFICE_LISTING_REVIEW_STATUS,
  PORTAL_OFFICE_LISTING_REVIEW_STATUS_COLOR,
} from "@/consts/portal";

import { PortalStaff, DEFAULT_PORTAL_STAFF } from "@/model/portal";
import { DEFAULT_EWELLUSER } from "@/model/ewellUser";

@Component({
  components: {
    ChangeContractDialog,
    OfficeInfo,
    AppAuthButton,
    OfficeStaffChangeDialog,
    OutputExcelDialog,
    InputExcelDialog,
    AppLoadingDialog,
  },
})
export default class PortalOfficeDetail extends Mixins(AxiosMixin, UtilMixin) {
  /** 初回ロード完了フラグ */
  private initLoadingDone = false;
  private portalOffice = null;
  private internalRemark = null;
  private adminPortalStaff: PortalStaff = { ...DEFAULT_PORTAL_STAFF };
  private showSaveCompleteBar = false;
  public loading = 0;
  private addressBookId = 0; // 住所録ID

  private PORTAL_OFFICE_IS_PUBLIC = PORTAL_OFFICE_IS_PUBLIC;
  private PORTAL_OFFICE_IS_PUBLIC_COLOR = PORTAL_OFFICE_IS_PUBLIC_COLOR;
  private PORTAL_OFFICE_APPROVAL_STATUS = PORTAL_OFFICE_APPROVAL_STATUS;
  private PORTAL_OFFICE_APPROVAL_STATUS_COLOR =
    PORTAL_OFFICE_APPROVAL_STATUS_COLOR;
  private PORTAL_OFFICE_LISTING_REVIEW_STATUS =
    PORTAL_OFFICE_LISTING_REVIEW_STATUS;
  private PORTAL_OFFICE_LISTING_REVIEW_STATUS_COLOR =
    PORTAL_OFFICE_LISTING_REVIEW_STATUS_COLOR;

  public async created() {
    await this.fetchPortalOffice(Number(this.$route.params.id));
    this.initLoadingDone = true;
  }

  /** ポータル事業所取得 */
  private async fetchPortalOffice(id: number) {
    this.loading = 1;
    return new Promise((resolve, reject) => {
      this.postJsonCheck(
        window.base_url + "/api/admin/portal_office/get",
        { portal_office_id: id },
        (res) => {
          console.log(res);
          this.portalOffice = res.data.portal_office;
          this.internalRemark = res.data.internal_remark;
          this.addressBookId = res.data.address_book_id || 0;

          // admin_portal_staffsが存在し、空でないかを確認
          if (
            res.data.admin_portal_staffs &&
            res.data.admin_portal_staffs.length > 0
          ) {
            this.adminPortalStaff = res.data.admin_portal_staffs[0];

            // ewell_userが存在することを確認
            if (!this.adminPortalStaff.ewell_user) {
              this.adminPortalStaff.ewell_user = DEFAULT_EWELLUSER;
            }
          } else {
            // admin_portal_staffsが空の場合、デフォルト値を設定
            this.adminPortalStaff = {
              ...DEFAULT_PORTAL_STAFF,
              ewell_user: DEFAULT_EWELLUSER,
            };
          }

          this.loading = 0;
          resolve(0);
        },
      );
    });
  }

  /** 詳細ボタンクリック時 */
  private goEdit(id: number) {
    /** 詳細画面へ遷移 */
    this.$router.push({ path: `/portal/portal-office-no-ibow/edit/${id}` });
  }

  /** 法人情報編集ボタンクリック時 */
  private goAgreementEdit(id: number) {
    this.$router.push({ path: `/portal/portal-agreement-no-ibow/edit/${id}` });
  }

  //代理ログイン
  private agentLogin(id: number) {
    this.postJsonCheck(
      window.base_url + "/api/admin/portallogin/portalstaff-agent-login",
      {
        portal_office_id: id,
      },
      (res) => {
        window.open(
          window.portal_url +
            "/signin?use_type=1&token=" +
            res.data.onetime_token,
          "_blank",
        );
      },
    );
  }

  //管理者ログイン
  private adminLogin(id: number) {
    this.postJsonCheck(
      window.base_url + "/api/admin/portallogin/portalstaff-admin-login",
      {
        portal_office_id: id,
      },
      (res) => {
        window.open(
          window.portal_url +
            "/signin?use_type=1&is_admin=true&token=" +
            res.data.onetime_token,
          "_blank",
        );
      },
    );
  }

  /** 承認するボタンクリック時 */
  private async approve(id: number) {
    if (!(await this.$openConfirm("承認しますか？"))) {
      return;
    }
    this.postJsonCheck(
      window.base_url + "/api/admin/portal_office/update_approval_status",
      { portal_office_id: id },
      () => {
        this.fetchPortalOffice(id).then(() => {
          this.showSaveCompleteBar = true;
        });
      },
    );
  }

  /** 掲載承認する */
  private async approveListingReviewStatus(id: number) {
    if (!(await this.$openConfirm("初回掲載情報を承認しますか？"))) {
      return;
    }
    this.postJsonCheck(
      window.base_url + "/api/admin/portal_office/update_listing_review_status",
      { portal_office_id: id },
      () => {
        this.fetchPortalOffice(id).then(() => {
          this.showSaveCompleteBar = true;
        });
      },
    );
  }

  /** iBowに再連携する */
  private async reconnectIbow(id: number) {
    if (
      !(await this.$openConfirm(
        "iBowに再連携しますか？一度停止したiBow連携を再開する機能であるため、新規連携の場合は使用しないでください。",
      ))
    ) {
      return;
    }
    this.postJsonCheck(
      window.base_url + "/api/admin/portal_ibow_link/relink",
      { portal_office_id: id },
      () => {
        this.fetchPortalOffice(id).then(() => {
          this.showSaveCompleteBar = true;
        });
      },
    );
  }

  /** 自動登録データ表示ボタンクリック時 */
  private openAddressBook(id: number) {
    window.open(`/portal/portal-office-no-ibow/detail/${id}`, "_blank");
  }
}
