









import { Component, Mixins, Prop } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";

@Component
export default class extends Mixins(UtilMixin) {
  /** 事業所請求情報 */
  @Prop() name!: string;

  /** 事業所請求情報 */
  @Prop() value!: string;
}
