























// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference types="@types/googlemaps" />
/*global google*/
import { Component, Emit, Prop, Ref, Vue, Watch } from "vue-property-decorator";
import AppDialog from "./AppDialog.vue";
import { PREFECTURES } from "../const/prefectures";

interface Latlng {
  lat: number;
  lng: number;
}

@Component({
  components: { AppDialog }
})
export default class AppMapButton extends Vue {
  /** GoogleMap用のdiv */
  @Ref() private readonly googleMapArea!: HTMLElement;

  /** 都道府県 */
  @Prop() private readonly prefecture!: number;

  /** 市区町村 */
  @Prop() private readonly cityAddress!: string;

  /** 番地 */
  @Prop() private readonly houseNumber!: string;

  /** 建物 */
  @Prop() private readonly building!: string;

  /** 緯度経度 */
  @Prop({ default: () => ({ lat: 0, lng: 0 }) })
  private readonly latlng!: Latlng;

  /** 保存 */
  @Emit("saved") saved(latlng: Latlng) {
    return latlng;
  }

  /** ダイアログ */
  private isOpenDialog = false;

  /** マップ */
  private map: google.maps.Map | null = null;

  /** マーカー */
  private marker: google.maps.Marker | null = null;

  /** ダイアログが開いたら */
  @Watch("isOpenDialog") private watchIsOpenDialog() {
    if (this.isOpenDialog) {
      this.$nextTick(async () => {
        let latlng = this.latlng;
        if (this.latlng.lat === 0 && this.latlng.lng === 0) {
          // もしも位置情報がなかった場合は、住所から緯度経度を取得
          latlng = await this.getLatLngByGeocoder(this.Address);
        }
        this.newMap(latlng);
      });
    }
  }

  /** ボタンのdisabled */
  private get ButtonDisabled() {
    return (
      this.prefecture === 0 ||
      this.cityAddress === "" ||
      this.houseNumber === ""
    );
  }

  /** 住所 */
  private get Address() {
    const prefecture = PREFECTURES.find(p => {
      return p.value === this.prefecture;
    });
    return (
      prefecture?.text + this.cityAddress + this.houseNumber + this.building
    );
  }

  /** 保存 */
  async save() {
    if (!(await this.$openConfirm("この位置情報を反映しますか？"))) {
      return;
    }
    const latlng = this.marker?.getPosition();
    if (latlng) {
      this.saved({ lat: latlng.lat(), lng: latlng.lng() });
    }
    this.isOpenDialog = false;
  }

  /** マップを開く */
  async newMap(latlng: Latlng) {
    // Map（地図）の作成
    this.map = new google.maps.Map(this.googleMapArea, {
      center: latlng,
      zoom: 16
    });

    // Maker（マーカー）の作成
    this.marker = new google.maps.Marker({
      position: latlng,
      map: this.map,
      draggable: true,
      title: "マップをクリックしたり、マーカーをドラッグして位置を変更できます"
    });

    //InfoWindow（吹き出し）の作成
    const infoWindow = new google.maps.InfoWindow({
      content: "<h3>" + this.Address + "</h3>"
    });

    // マップのクリックイベントを追加
    this.map.addListener("click", e => {
      // マーカーの位置をクリックされた位置に移動
      this.marker?.setPosition(e.latLng);
    });

    // マーカークリック時に吹き出しを表示するイベントを追加
    this.marker.addListener("click", () => {
      if (this.map && this.marker) {
        infoWindow.open(this.map, this.marker);
      }
    });
  }

  /** 位置情報を取得して返却する */
  getLatLngByGeocoder(address: string): Promise<Latlng> {
    return new Promise<Latlng>(resolve => {
      new google.maps.Geocoder().geocode(
        {
          address,
          region: "jp"
        },
        (results, status) => {
          if (status === google.maps.GeocoderStatus.OK) {
            resolve({
              lat: results[0].geometry.location.lat(),
              lng: results[0].geometry.location.lng()
            });
          } else {
            resolve({
              lat: 35.6813,
              lng: 139.767066
            });
          }
        }
      );
    });
  }
}
