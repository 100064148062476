var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-text',[_c('app-text-field',{staticClass:"mt-2",attrs:{"label":"絞り込み","width":"100%","outlined":false,"hide-details":"auto"},model:{value:(_vm.searchKey),callback:function ($$v) {_vm.searchKey=$$v},expression:"searchKey"}}),_c('v-data-table',{staticClass:"mt-2 elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.searchKey,"no-data-text":"企業データがありません","header-props":{ class: 'fixed-header' }},scopedSlots:_vm._u([{key:"item.use_ibow",fn:function(ref){
var item = ref.item;
return [(item.ibow_office_id != 0)?_c('div',[_vm._v("iBow利用")]):_c('div')]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(item.ibow_office_id != 0)?_c('div',[_c('AppAuthButton',{attrs:{"small":"","label":"詳細"},on:{"click":function($event){return _vm.goDetailIbow(item.id)}}})],1):_c('div',[_c('AppAuthButton',{attrs:{"small":"","label":"詳細"},on:{"click":function($event){return _vm.goDetailNoIbow(item.id)}}})],1)]}},{key:"item.is_public",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.idToText(item.is_public, _vm.PORTAL_OFFICE_IS_PUBLIC))+" ")]}},{key:"item.is_carelogood",fn:function(ref){
var item = ref.item;
return [(item.is_carelogood)?_c('div',{staticClass:"text-center"},[_vm._v("◯")]):_c('div')]}},{key:"item.listing_review_status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"mr-1",attrs:{"dark":"","small":"","label":"","color":_vm.idToText(
                item.listing_review_status,
                _vm.PORTAL_OFFICE_LISTING_REVIEW_STATUS_COLOR
              )}},[_c('span',{staticStyle:{"display":"inline-block","width":"100%","text-align":"center"}},[_vm._v(" "+_vm._s(_vm.idToText( item.listing_review_status, _vm.PORTAL_OFFICE_LISTING_REVIEW_STATUS ))+" ")])])]}},{key:"item.approval_status",fn:function(ref){
              var item = ref.item;
return [_c('v-chip',{staticClass:"mr-1",attrs:{"dark":"","small":"","label":"","color":_vm.idToText(
                item.approval_status,
                _vm.PORTAL_OFFICE_APPROVAL_STATUS_COLOR
              )}},[_c('span',{staticStyle:{"display":"inline-block","width":"100%","text-align":"center"}},[_vm._v(" "+_vm._s(_vm.idToText(item.approval_status, _vm.PORTAL_OFFICE_APPROVAL_STATUS))+" ")])])]}},{key:"item.type",fn:function(ref){
              var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-bold text-center",staticStyle:{"width":"40px","min-width":"40px"},attrs:{"small":"","color":item.type === 3 ? 'error' : 'success',"dark":""}},[_vm._v(" "+_vm._s(item.type === 3 ? "医" : "看")+" ")])]}}],null,true)})],1)],1),_c('app-loading-dialog',{attrs:{"loading":_vm.loading}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }